<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0C13.5312 0 14 0.46875 14 1.03125V13C14 13.5625 13.5312 14 13 14H0.96875C0.4375 14 0 13.5625 0 13V1.03125C0 0.46875 0.4375 0 0.96875 0H13ZM4.21875 12V5.34375H2.15625V12H4.21875ZM3.1875 4.40625C3.84375 4.40625 4.375 3.875 4.375 3.21875C4.375 2.5625 3.84375 2 3.1875 2C2.5 2 1.96875 2.5625 1.96875 3.21875C1.96875 3.875 2.5 4.40625 3.1875 4.40625ZM12 12V8.34375C12 6.5625 11.5938 5.15625 9.5 5.15625C8.5 5.15625 7.8125 5.71875 7.53125 6.25H7.5V5.34375H5.53125V12H7.59375V8.71875C7.59375 7.84375 7.75 7 8.84375 7C9.90625 7 9.90625 8 9.90625 8.75V12H12Z"
      fill="currentColor"
    />
  </svg>
</template>
